<template>
    <div>
    <v-container fluid grid-list-xl>
            <v-layout row wrap>
                <v-flex xl6 lg6 md6 sm7 xs12>
                    <div class="welcome-message mb-4">
                        <h4>{{ greeting }}</h4>
                        <p>Welcome to your Cynergic customer dashboard. From here you will be able to get an overview of your current IT and Communications services and accounts.</p>
                    </div>
                    <v-card>
                        <v-card-title>Notices and Alerts</v-card-title>
                        <v-card-text class="text-center fs-12">There are no current alerts</v-card-text>
                    </v-card>
                    <v-card class="mt-4" v-if="me.permissions.map((p) => p.name).includes('manage-links')">
                        <v-card-title>Quick Links</v-card-title>
                        <v-card-text>
                            <v-skeleton-loader
                                v-if="loadingQuickLinks"
                                type="list-item-two-line"
                            ></v-skeleton-loader>
                            <div v-else>
                                <v-list v-if="quickLinks.length">
                                    <v-list-item
                                        v-for="link in quickLinks"
                                        :key="link.id"
                                        :href="link.url"
                                        target="_blank"
                                    >
                                        <v-list-item-icon>
                                            <v-icon v-text="`${link.icon} fa-fw`"></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ link.title }}</v-list-item-title>
                                            <div class="tw-text-sm tw-text-gray-700 tw-mt-1">{{ link.description }}</div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <div v-else>
                                    No links available.
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>                
                </v-flex>
                <v-flex xl6 lg6 md6 xs12 sm5 class="d-sm-block d-none">
                    <div class="d-custom-flex align-items-center justify-center">
                        <img class="tw-max-w-full" src="/img/agency-welcome.png" alt="agency block">
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Http from '@/utils/httpClient';

export default {
    data() {
        return {
            loadingSummary: false,
            loadingServices: false,
            loadingDetails: false,
            loadingQuickLinks: false,
            quickLinks: [],
            services: [],
            summary: {},
            details: {
                contact: {},
                address: {
                    street: ''
                },
            },
        };
    },
    computed: {
        ...mapGetters(['me']),
        greeting() {
            const hour = (new Date()).getHours()

            if (hour < 12) {
              return `Good morning, ${this.me.first_name}.`;
            } 

            if (hour < 18) {
              return `Good afternoon, ${this.me.first_name}.`;
            }
            
            return `Good evening, ${this.me.first_name}.`;
        }
    },
    mounted() {
        this.getSummary();
        this.getServices();
        this.getDetails();

        if (this.me.permissions.map((p) => p.name).includes('manage-links')) {
            this.getQuickLinks();
        }
    },
    methods: {
        getQuickLinks() {
            this.loadingQuickLinks = true;
            Http.get('links')
                .then(({ data }) => {
                    this.loadingQuickLinks = false;
                    this.quickLinks = data.data;
                })
                .catch(() => {
                    this.loadingQuickLinks = false;
                })
        },
        getSummary() {
            this.loadingSummary = true;
            Http.get('summary')
                .then(({ data }) => {
                    this.loadingSummary = false;
                    this.summary = data;
                })
                .catch(() => {
                    this.loadingSummary = false;
                })
        },
        getServices() {
            if (this.me.permissions.map((p) => p.name).includes('manage-customer-services')) {
                this.loadingServices = true;
                Http.get('services')
                    .then(({ data }) => {
                        this.loadingServices = false;
                        this.services = data;
                    })
                    .catch(() => {
                        this.loadingServices = false;
                    })
            }
        },
        getDetails() {
            this.loadingDetails = true;
            Http.get('details')
                .then(({ data }) => {
                    this.loadingDetails = false;
                    this.details = data;
                })
                .catch(() => {
                    this.loadingDetails = false;
                })
        }
    }
};
</script>
